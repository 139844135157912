.content {
  height: 100%!important;
  width: 100%!important;
	background-color: #fff;
  overflow: hidden!important;
  position: relative;
}
.logo{
    z-index: 2!important;
    position: absolute!important;
    width: 15%!important;
    min-width: 350px!important;
    left: 25%!important;
    top: 9%!important;
}
.home-text {
    z-index: 2!important;
    position: absolute!important;
    max-width: 100%!important;
    padding-left: 45px!important;
    left:28%!important;
    top: 18%!important;
    line-height: 2.2!important;
}
.gotoblog-text {
    position: relative!important;
    z-index: 2!important;
    text-align: center;
    top: 50%;
	  color: #819fff!important;
}
.bubble-wrapper {
    position: absolute;
    top: 95vh;
    left: 16vw;
    transform: translate(-50%, -50%);
    width: 40vh;
    height: 40vh;
    z-index: 4;
  }
  
  .bubble-container, .inner-bubble-container, .inner-bubble-container2 {
    position: absolute;
    width: 35vh;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center center;
  }
  .bubble-container {
    animation: rotate-and-expand-circle 3s forwards;
  }
  .inner-bubble-container {
    animation: rotate-and-expand-inner-circle 3s forwards;
  }
  .inner-bubble-container2 {
    animation: rotate-and-expand-inner-circle2 3s forwards;
  }
  
  .bubble-wrapper-inner {
    position: absolute;
    animation: rotate-bubble 3s linear infinite;
  }
  
  .bubble {
    width: 5vw;
    height: auto;
    padding: 2vh;
    background-color: #8aa5fb;
    color: white;
    font-size: 1.5vh;
    text-align: center;
    border-radius: 15px;
    transform: rotate(100deg);
    animation: expand-bubble 3s forwards;
  }
  .inner-bubble {
    width: 5vw;
    height: auto;
    padding: 2vh;
    background-color: #8aa5fb;
    color: white;
    font-size: 1.5vh;
    text-align: center;
    border-radius: 15px;
    transform: rotate(100deg);
    animation: expand-bubble 3s forwards;
  }
  .inner-bubble2 {
    width: 5vw;
    height: auto;
    padding: 2vh;
    background-color: #8aa5fb;
    color: white;
    font-size: 1.5vh;
    text-align: center;
    border-radius: 15px;
    transform: rotate(100deg);
    animation: expand-bubble 3s forwards;
  }
  .text-line {
    width: 90%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.75);
    margin: 0.35vw 0;
    margin-left: 0.12vw;
  }
  .bubble:before {
    content: '';
    position: absolute;
    bottom: -1vh;
    right: 1vh;
    width: 0;
    height: 0;
    border-left: 1.5vh solid transparent;
    border-right: 0vh solid transparent;
    border-top: 1.5vh solid #8aa5fb;
  }
  .inner-bubble:before {
    content: '';
    position: absolute;
    bottom: -1.3vh;
    right: 1vh;
    width: 0;
    height: 0;
    border-left: 1.5vh solid transparent;
    border-right: 0vh solid transparent;
    border-top: 1.5vh solid #8aa5fb;
  }
  .inner-bubble2:before {
    content: '';
    position: absolute;
    bottom: -1.3vh;
    right: 1vh;
    width: 0;
    height: 0;
    border-left: 1.5vh solid transparent;
    border-right: 0vh solid transparent;
    border-top: 1.5vh solid #8aa5fb;
  }
  
  .bubble-wrapper-inner:nth-child(1) { transform: rotate(0deg) translate(16vh); }
  .bubble-wrapper-inner:nth-child(2) { transform: rotate(45deg) translate(16vh); }
  .bubble-wrapper-inner:nth-child(3) { transform: rotate(90deg) translate(16vh); }
  .bubble-wrapper-inner:nth-child(4) { transform: rotate(135deg) translate(16vh); }
  .bubble-wrapper-inner:nth-child(5) { transform: rotate(180deg) translate(16vh); }
  .bubble-wrapper-inner:nth-child(6) { transform: rotate(225deg) translate(16vh); }
  .bubble-wrapper-inner:nth-child(7) { transform: rotate(270deg) translate(16vh); }
  .bubble-wrapper-inner:nth-child(8) { transform: rotate(315deg) translate(16vh); }
  
  @keyframes rotate-and-expand-circle {
    0% {
      transform: rotate(0deg) scale(1);
    }
    100% {
      transform: rotate(-45deg) scale(2); /* Rotating in the opposite direction */
    }
  }
  @keyframes rotate-and-expand-inner-circle {
    0% {
      transform: rotate(-45deg) scale(.5);
    }
    100% {
      transform: rotate(0deg) scale(1); /* Rotating in the opposite direction */
    }
  }
  @keyframes rotate-and-expand-inner-circle2 {
    0% {
      transform: rotate(0deg) scale(.25);
    }
    100% {
      transform: rotate(-45deg) scale(0.5); /* Rotating in the opposite direction */
    }
  }
  
  
  @keyframes expand-bubble {
    0% {
      width: 5vh;
      height: auto;
      font-size: 1.5vh;
    }
    100% {
      width: 5.5vh;
      height: auto;
      font-size: 2.25vh;
    }
  }