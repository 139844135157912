.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  top: 15px;
  left: 15px;
  user-select: none;
  pointer-events: none;
}

.editor-paragraph {
  margin: 0 0 15px 0;
  position: relative;
}

.editor-input {
  overflow-y: auto; 
  overflow-x: hidden; 
  padding-top: 13px;
  padding-left: 13px; 
  padding-right: 13px;
  border: 1px solid; 
  border-radius: 4px; 
  box-sizing: border-box; 
  text-align: left;
  white-space: pre-wrap; 
}
.editor-input:focus {
border-color: #436efa; 
border-width: 2px; 
outline: none; 
}

.editor-input a {
  color: #436efa;
  text-decoration: underline;
}

.editor-input a:hover {
  cursor: pointer;
}
